import { useFetcher } from '@remix-run/react'
import { type FormEventHandler, useState } from 'react'
import Button from '~/core/ui/Button'
import Modal from '~/core/ui/Modal'
import TextField from '~/core/ui/TextField'

export default function EditTranscriptModal({
  open,
  setOpen,
  transcript,
  onConfirm,
}: React.PropsWithChildren<{
  transcript: string
  onConfirm: (title: string) => void
  open: boolean
  setOpen: (open: boolean) => void
}>) {
  const [title, setTitle] = useState(transcript)
  const fetcher = useFetcher()
  const submitting = fetcher.state === 'submitting'

  const onSubmit: FormEventHandler<HTMLFormElement>
    = async (event) => {
      event.preventDefault()
      onConfirm(title)
      setOpen(false)
    }

  return (
    <>
      <Modal heading="Edit Transcript" isOpen={open} setIsOpen={setOpen}>
        <form onSubmit={onSubmit} className="space-y-2">
          <TextField.Label>
            Title
            <TextField.Input
              type="text"
              required={true}
              name="title"
              onChange={e => setTitle((e.target as HTMLInputElement).value)}
              value={title}
            />
          </TextField.Label>

          <div className="flex justify-end space-x-2">
            <Button loading={submitting} variant="flat" type="submit">
              Save
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
