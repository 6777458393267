import Button from '~/core/ui/Button'
import Modal from '~/core/ui/Modal'

export function ConfirmDeleteTranscriptModal({
  open,
  setOpen,
  onConfirm,
  transcriptTitle,
  title,
}: React.PropsWithChildren<{
  transcriptTitle: string
  onConfirm: () => void
  open: boolean
  setOpen: (open: boolean) => void
  title?: string
}>) {
  function onSubmit() {
    onConfirm()
    setOpen(false)
  }

  const defaultTitle = `You are about to delete the transcript`
  return (
    <Modal heading="Deleting Transcript" isOpen={open} setIsOpen={setOpen}>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-2 text-sm">
          <p>
            {title ?? defaultTitle}
            {' '}
            <b>{transcriptTitle}</b>
          </p>

          <p>Do you want to continue?</p>
        </div>

        <div className="flex justify-end space-x-2">
          <Button variant="destructive" color="danger" onClick={onSubmit}>

            Yes, delete transcript
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default ConfirmDeleteTranscriptModal
