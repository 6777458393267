import { Link, ShouldRevalidateFunctionArgs, useLoaderData, useLocation, useNavigate, useSearchParams } from '@remix-run/react'
import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/server-runtime'
import { Trans } from 'react-i18next'
import AppHeader from '~/components/AppHeader'
import getSupabaseServerClient from '~/core/supabase/server-client.server'
import { PageBody } from '~/core/ui/Page'
import type { Project as IProject } from '~/lib/transcript/queries'
import { deleteTranscript, getDefaultTranscriptions, getProjectTranscripts, getTranscriptProject, getTranscriptProjectsLabel, updateTranscript } from '~/lib/transcript/queries'
import { json, redirect } from '@remix-run/server-runtime'
import Button from '~/core/ui/Button'
import { CircleHelp, CirclePlay } from 'lucide-react'
import Divider from '~/core/ui/Divider'
import FilesTable from './FilesTable'
import { getOrganizationId } from '~/utils/getOrganisations'
import { getUserId } from '~/utils/getUserId'
import { toast } from 'sonner'
import getLogger from '~/core/logger'
import {captureException, ErrorBoundary} from "@sentry/remix"

const DEFAULT_PROJECT: IProject = {
  id: '1',
  title: 'Default projects',
  created_at: new Date().toLocaleDateString(),
  created_by: '',
  objective: '',
  brief: '',
  organization_id: 8,
}
const logger = getLogger()
export async function loader({ request: req, params }: LoaderFunctionArgs) {
  const client = getSupabaseServerClient(req)
  const userId = await getUserId(req)
  try {
    const organizationId = await getOrganizationId(req)
    const { data: projectsLabelList } = await getTranscriptProjectsLabel(client, { orgId: organizationId! })
    if (params.id === 'default') {
      const { data: defaultTranscriptions } = await getDefaultTranscriptions(
        client,
        { orgId: organizationId!, userId },
      )
      return json({
        project: DEFAULT_PROJECT,
        transcripts: defaultTranscriptions || [],
        projectsLabelList,
        isDefault: true,
      })
    }
    const { data: project } = await getTranscriptProject(client, { id: params.id! })
    if (!project) {
      return redirect('/transcribe')
    }

    const { data: projectTranscripts } = await getProjectTranscripts(client, { projectId: project.id, orgId: organizationId! })

    return json(
      { project, transcripts: projectTranscripts || [], projectsLabelList, isDefault: false },
    )
  }
  catch (error) {
    logger.error(error)
    if (error instanceof Response) {
      throw error
    }
  }
}

export async function action({ request }: ActionFunctionArgs) {
  try {
  const client = getSupabaseServerClient(request)
  const transcriptData = await request.formData()
  const transcriptIdList = transcriptData.getAll('transcriptId') as string[]
  const transcriptTitle = transcriptData.get('title') as string
  const destinationProjectId: string
    = transcriptData.get('destinationProjectId') as string
  const currentProjectId = transcriptData.get('currentProjectId') as string

  if (!transcriptIdList.length) {
    return
  }

  if (request.method === 'DELETE') {
    const deleteTranscriptRequests = transcriptIdList.map(async id => (
      deleteTranscript(client, { id })
    ))
    await Promise.all(deleteTranscriptRequests)
  }
  else if (request.method === 'PUT' && destinationProjectId && currentProjectId) {
    const updateTranscriptRequests = transcriptIdList.map(async id => (
      updateTranscript(
        client,
        { project_id: destinationProjectId, id },
      )
    ))
    await Promise.all(updateTranscriptRequests)
  }
  else if (request.method === 'POST' && transcriptTitle) {
    await updateTranscript(client, { title: transcriptTitle, id: transcriptIdList[0] })
  }
  } catch(e) {
    logger.error(e) 
    captureException(e)
  }
  return null
}

export function shouldRevalidate(args: ShouldRevalidateFunctionArgs) {
  if (args.actionStatus) {
    return true
  }

  const nextTranscriptionIdList = args.nextUrl.searchParams.getAll("transcriptionId")
  const currentTranscriptionIdList = args.currentUrl.searchParams.getAll("transcriptionId")
  const isCheckedTranscriptionChanged =
    nextTranscriptionIdList.length !== currentTranscriptionIdList.length ||
    !nextTranscriptionIdList.some((id) =>
      currentTranscriptionIdList.includes(id),
    );
  if (isCheckedTranscriptionChanged) {
    return false
  }
  return args.defaultShouldRevalidate
}
const AppHeaderTitleLinkItems = [
  <Link to="/transcribe" className='hover:underline'>Transcribe Projects</Link>,
]; 
export default function Project() {
  const { project, transcripts, isDefault } = useLoaderData<typeof loader>()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  function navigateProjectAnalysis() {
    const selectedTranscriptions = searchParams.getAll('transcriptionId')
    if (!selectedTranscriptions.length) {
      return toast.error('Please select at least one transcription!')
    }
    if (selectedTranscriptions.length > 8) {
      return toast.error('Please select less than 8 transcriptions!')
    }
    navigate(`${location.pathname}/analysis?${searchParams.toString()}`)
  }
  return (
    <>
      <AppHeader
        title={[...AppHeaderTitleLinkItems, project?.title]}
        description={<Trans i18nKey="AI powered transcription" />}
      />
      <PageBody>
        <div className="w-full pt-1 pb-7 flex justify-between items-center">
          <span className="font-bold">Files</span>
          {!isDefault && (
            <div className="flex space-x-8">
              <Button onClick={navigateProjectAnalysis}>
                <CircleHelp className="mr-2" />
                Project Analysis
              </Button>
              <Button onClick={() => navigate(`${location.pathname}/new`)}>
                <CirclePlay className="mr-2" />
                Create
              </Button>
            </div>
          )}
        </div>
        <div className="min-h-full">
          <Divider />
          <ErrorBoundary fallback={<>Error occured during transcriptions render</>}>
            <FilesTable isDefault={isDefault} transcripts={transcripts || []} />
          </ErrorBoundary>
        </div>
      </PageBody>
    </>
  );
}
