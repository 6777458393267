import { Link, useLocation, useSearchParams } from '@remix-run/react'
import {  InfoIcon } from 'lucide-react'
import {  getTranscriptStatus, type Transcript } from '~/lib/transcript/queries'
import { Tooltip, TooltipContent, TooltipTrigger } from '~/core/ui/Tooltip'
import Badge, { type Color as BadgeColor } from '~/core/ui/Badge'
import useTranscriptionFilesTable from '~/core/hooks/use-transcription-files-table'
import { ProjectActionSettings } from './ProjectActionSettings'
import { cn } from '~/core/generic/shadcn-utils'
import { useQuery } from '@tanstack/react-query'
import useSupabase from '~/core/hooks/use-supabase'
import { StatusBadge } from '../_app.transcribe.$id.transcription.$transcriptionId/components/StatusBadge'

const columns = [
  {
    accessorKey: 'title',
    header: 'Title',
  },
  {
    accessorKey: 'uploadDate',
    header: 'Upload date',
  },
  {
    accessorKey: 'status',
    header: '',
  },
  {
    accessorKey: 'action',
    header: <InfoTooltip />,
  },
] as const
function InfoTooltip() {
  return (
    <Tooltip>
      <TooltipTrigger>
        <InfoIcon className="cursor-pointer" />
      </TooltipTrigger>

      <TooltipContent sideOffset={4}>
        Mark the transcripts you want to analyze
      </TooltipContent>
    </Tooltip>
  )
}

interface FileTableRowProps {
  children?: React.ReactNode;
  id: string;
  title: string;
  uploadDate: string;
  status: Transcript["status"];
}
const POLL_INTERVAL = 5000 // in ms

function FileTableRow({
  children,
  title,
  id,
  status: initialStatus,
  uploadDate,
}: FileTableRowProps) {
  const client = useSupabase()
  const { data: status } = useQuery({
    queryKey: ['transcription-status', id],
    queryFn: () =>
      getTranscriptStatus(client, { transcriptionId: id }).then(
        (res) => res.data?.status,
      ),
    initialData: initialStatus,
    refetchInterval: ({state: {data: status}}) => {
      if (status !== 'COMPLETED' && status !== 'ERROR') {
        return POLL_INTERVAL;
      }
      return false;
    },
    enabled: initialStatus !== "COMPLETED" && initialStatus !== "ERROR"
  });
  const location = useLocation();
  const isNotCompleted = status !== "COMPLETED" && status !== "ERROR" 
  return (
    <tr
      className={cn(
        'border border-[#F9FAFB]',
        isNotCompleted && '[&_td]:opacity-50',
      )}
    >
      <td className="py-4 max-w-[300px]">
        <Link to={`${location.pathname}/transcription/${id}`} className='hover:underline'>{title}</Link>
      </td>
      <td className="py-4 ">{uploadDate}</td>
      <td className='flex justify-center'>
      {status && <StatusBadge status={status} /> }
      </td>

      {children}
    </tr>
  );
}

export default function FilesTable({
  transcripts,
  isDefault,
}: {
  transcripts: Transcript[];
  isDefault?: boolean;
}) {
  const [searchParams] = useSearchParams();
  const {
    data,
    moveTranscript,
    setIsCheckedTranscription,
    deleteTranscript,
    editTranscript,
  } = useTranscriptionFilesTable({ transcripts });

  return (
    <div className="border-[#F9FAFB] min-h-full px-4 border rounded-[6px]">
      <table className="min-h-full min-w-full border-separate border-spacing-x-4">
        <thead>
          <tr>
            {columns
              .filter(
                ({ accessorKey }) => !isDefault || accessorKey !== 'action',
              )
              .map(({ header }, idx) => (
                <th
                  key={idx}
                  className="text-left py-3 text-[#6B7280] last:flex [&:nth-child(4n)]:justify-end [&:nth-child(4n)]:pr-[35px]"
                >
                  {header}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <FileTableRow key={row.id} {...row}>

              <td>
                <ProjectActionSettings
                  status={row.status}
                  changeIsCheckedStatus={(id) => setIsCheckedTranscription(id)}
                  isChecked={searchParams.has('transcriptionId', row.id)}
                  transcriptionId={row.id}
                  transcription={row.title}
                  moveTranscript={moveTranscript}
                  deleteTranscript={deleteTranscript}
                  editTranscript={editTranscript}
                />
              </td>
            </FileTableRow>
          ))}
        </tbody>
      </table>
    </div>
  );
}
